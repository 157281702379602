<template>
  <div>
    <!-- select 2 demo -->
    <b-modal
      id="modal-login"
      no-close-on-backdrop
      centered
      ok-only
      size="lg"
      hide-footer="true"
      title="Add Event"
    >
      <b-form>
        <b-row>
          <b-col>
            <b-form-group
              ref="image"
              label="Image"
              invalid-feedback="Image is required."
            >
              <b-media no-body>
                <div v-if="logoloading == true">
                  <b-spinner
                    variant="primary"
                    type="grow"
                    class="m-5"
                    label="Spinning"
                  ></b-spinner>
                </div>

                <b-media-aside>
                  <b-link>
                    <b-img
                      ref="previewEl"
                      rounded
                      src="https://cdn.cloudious.net/file-1678189226453-406060527.png"
                      @click="$refs.fileProfile.click()"
                      v-if="logoloading == false"
                      style="height: 150px; width: 150px; object-fit: cover"
                    />
                  </b-link>

                  <b-link v-if="logoloading == 'loaded'">
                    <div>
                      <i
                        @click="deleteLogo()"
                        style="margin-top: 70px; margin-left: 70px"
                        class="fa fa-trash fa-md text-danger position-absolute"
                      ></i>
                    </div>
                    <b-img
                      rounded
                      :src="plus"
                      style="height: 150px; width: 150px; object-fit: cover"
                    />
                    <br />
                  </b-link>

                  <input
                    type="file"
                    id="fileProfile"
                    hidden
                    ref="fileProfile"
                    @change="selectlogo()"
                    accept="image/*"
                  />
                </b-media-aside>
              </b-media>
            </b-form-group>
          </b-col>
        </b-row>
        <b-form-group
          label="Title"
          invalid-feedback="Title is required."
          ref="title"
        >
          <b-form-input
            v-model.trim="myObj.title"
            placeholder="Enter title here"
            @focusout="checkTitle()"
          />
        </b-form-group>
      </b-form>
      <br />
      <div>
        <b-button
          class="float-right ml-5"
          variant="primary"
          small
          :disabled="logoloading == true || request"
          @click="AddFlavour()"
        >
          <b-spinner
            v-if="request"
            variant="light"
            small
            type="grow"
            label="Spinning"
          ></b-spinner>
          <span class="text-nowrap" v-else>Save</span>
        </b-button>
      </div>
    </b-modal>

    <b-card no-body class="mb-0">
      <div class="m-2">
        <b-row>
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-start">
              <div style="padding-right: 15px">
                <b-button @click="OpenAddModal(0)" variant="primary">
                  <feather-icon icon="PlusIcon" class="mr-50" />
                  <span class="text-nowrap">Add Event</span>
                </b-button>
              </div>
              <!-- <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              /> -->
            </div>
          </b-col>
        </b-row>
      </div>

      <div>
        <b-table :items="items" :fields="fields" striped>
          <!-- <template #cell(#)="data">
            {{ data.index + 1 }}
          </template> -->

          <template #cell(image)="data">
            <b-img
              :src="data.item.image"
              style="width: 100px; height: 100px; object-fit: cover"
              rounded
              alt="image"
            />
          </template>

          <template #cell(actions)="data">
            <template>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="btn-icon"
                v-b-tooltip.hover.v-primary
                v-b-tooltip.placement.left
                title="Edit"
                @click="OpenAddModal(data.item.id)"
              >
                <feather-icon size="16" icon="EditIcon" />
              </b-button>

              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-danger"
                class="ml-1 btn-icon"
                v-b-tooltip.hover.v-danger
                v-b-tooltip.placement.right
                title="Delete"
                @click="Delete(data.item.id)"
              >
                <feather-icon size="16" icon="Trash2Icon" />
              </b-button>
            </template>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
            </b-col>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalOrders"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BMediaAside,
  BSpinner,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  VBTooltip,
  BFormGroup,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
//import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from "@core/utils/filter";
//import useAllOrdersList from './useAllOrdersList'
//import orderStoreModule from '../allordersStoreModule'
import flatPickr from "vue-flatpickr-component";
import { BFormSelect } from "bootstrap-vue";
//import Multiselect from "vue-multiselect";
import VueGallery from "vue-gallery";
import moment from "moment";
import { forEach } from "postcss-rtl/lib/affected-props";

export default {
  components: {
    //Multiselect,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BFormGroup,
    BTable,
    BMedia,
    BMediaAside,
    BSpinner,
    BImg,
    //BAvatar,
    BLink,
    BBadge,
    //BDropdown,
    //BDropdownItem,
    BPagination,
    vSelect,
    flatPickr,
    // gallery: VueGallery
  },
  props: {
    /* modalFilter: {
    type: [String, null],
    default: null,
    },
    modalFilterOptions: {
    type: Array,
    required: true,
    }*/
  },
  created() {
    // this.LoadData();
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      plus: "https://cdn.cloudious.net/file-1678189226453-406060527.png",
      fileProfile: "",
      logoloading: false,
      request: false,
      imgList: [],
      fields: [
        { label: "image", key: "image" },
        { label: "Title", key: "title" },
        "actions",
      ],
      items: [],
      searchQuery: "",
      filterOptions: [
        { text: "Most sold product", value: "mostSold" },
        { text: "Least sold product", value: "leastSold" },
        { text: "Most popular product", value: "mostPopular" },
        { text: "Least popular product", value: "leastPopular" },
        { text: "Most profitable product", value: "mostProfitable" },
      ],
      rangeDate: null,
      myObj: {
        id: 0,
        title: "",
        image: "",
      },
    };
  },
  methods: {
    checkImage() {
      var elem = this.$refs["image"];
      if (this.myObj.image == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    checkTitle() {
      var elem = this.$refs["title"];
      if (this.myObj.title == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },

    OpenAddModal(id) {
      if (id == 0) {
        this.myObj = {
          id: 0,
          title: "",
          image: "",
        };
        this.logoloading = false;
        this.plus =
          "https://cdn.cloudious.net/file-1678189226453-406060527.png";
        this.$bvModal.show("modal-login");
      } else {
        var axios = require("axios");
        var config = {
          method: "get",
          url: "https://aafia.cloudious.net/api/Events/" + id,
          headers: {
            Authorization: "bearer " + this.$store.state.userData.token,
          },
        };
        axios(config)
          .then((response) => {
            this.myObj = response.data.data;
            if (this.myObj.image) {
              this.logoloading = "loaded";
              this.plus = this.myObj.image;
            } else {
              this.logoloading = false;
              this.plus =
                "https://cdn.cloudious.net/file-1678189226453-406060527.png";
            }
            this.$bvModal.show("modal-login");
          })
          .catch(function(error) {
            console.log(error);
          });
      }
    },

    Delete(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          var axios = require("axios");
          var config = {
            method: "delete",
            url: "https://aafia.cloudious.net/api/Events/" + id,
            headers: {
              Authorization: "bearer " + this.$store.state.userData.token,
            },
            data: this.myObj,
          };
          axios(config)
            .then((response) => {
              if (response.data.status === "success") {
                Swal.fire(
                  "Success!",
                  "Event has been deleted.",
                  "success"
                ).then((res) => {
                  this.LoadData();
                });
              }
            })
            .catch(function(error) {
              console.log(error);
            });
        }
      });
    },
    selectlogo() {
      this.fileProfile = this.$refs.fileProfile.files[0];

      var axios = require("axios");
      if (this.fileProfile !== "") {
        this.logoloading = true;
        let formData = new FormData();
        formData.append("file", this.fileProfile);
        axios
          .post("https://upload.myskool.app", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })

          .then((e) => {
            // console.log(e.data);
            this.plus = e.data.myresp[0].path;

            this.myObj.image = this.plus;
            this.logoloading = "loaded";
            this.checkImage();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },

    deleteLogo() {
      this.myObj.image = "";
      this.logoloading = false;
      this.$refs.fileProfile.value = null;
    },
    AddFlavour() {
      this.checkImage();
      this.checkTitle();
      if (this.checkImage() == false || this.checkTitle() == false) {
        return this.$bvToast.toast("Please fill the form correctly.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-bottom-center",
        });
      } else {
        this.request = true;
        console.log(this.myObj);

        // if (this.myObj.id == 0) {
        //   var axios = require("axios");

        //   var config = {
        //     method: "post",
        //     url: "https://aafia.cloudious.net/api/Events",
        //     headers: {
        //       Authorization: "bearer " + this.$store.state.userData.token,
        //     },
        //     data: this.myObj,
        //   };

        //   axios(config)
        //     .then((response) => {
        //       if (response.data.status === "success") {
        //         // console.log(response.data);
        //         this.request = false;

        //         this.$bvModal.hide("modal-login");
        //         this.LoadData();
        //         Swal.fire("Success!", "Event Added Successfully.", "success");
        //       }
        //     })
        //     .catch(function(error) {
        //       console.log(error);
        //       this.request = false;
        //     });
        // } else {
        //   //Edit
        //   var axios = require("axios");
        //   var config = {
        //     method: "put",
        //     url: "https://aafia.cloudious.net/api/Events/" + this.myObj.id,
        //     headers: {
        //       Authorization: "bearer " + this.$store.state.userData.token,
        //     },
        //     data: this.myObj,
        //   };
        //   axios(config)
        //     .then((response) => {
        //       if (response.data.status === "success") {
        //         this.request = false;

        //         this.$bvModal.hide("modal-login");
        //         // console.log(response.data);
        //         this.LoadData();

        //         this.$bvToast.toast("Event updated.", {
        //           title: "Success!",
        //           variant: "success",
        //           toaster: "b-toaster-top-center",
        //         });
        //       } else {
        //         this.$bvToast.toast("Something went wrong.", {
        //           title: "Error!",
        //           variant: "danger",
        //           toaster: "b-toaster-top-center",
        //         });
        //         this.request = false;
        //       }
        //     })
        //     .catch(function(error) {
        //       console.log(error);
        //       this.request = false;
        //     });
        // }
      }
    },

    LoadData() {
      var axios = require("axios");

      var config = {
        method: "get",
        url: "https://aafia.cloudious.net/api/Events",
        headers: {
          Authorization: "bearer " + this.$store.state.userData.token,
        },
      };

      axios(config)
        .then((response) => {
          this.items = response.data.data;
          // console.log(this.items);
        })
        .catch(function(error) {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style scoped>
.image {
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  margin: 5px;
}
</style>
